<template>
  <div class="HomeworkItem">
    <div class="header">
      <div class="title">
        <el-tooltip
          class="item"
          effect="dark"
          :content="data.subjectName"
          placement="top"
        >
          <div class="subject">{{ data.subjectName }}</div>
        </el-tooltip>

        <div class="year">{{ getLevelName(data.level) }}{{ data.year }}级</div>
        <el-tooltip
          class="item"
          effect="dark"
          :content="data.homeworkName"
          placement="top"
        >
          <div class="name">{{ data.homeworkName }}</div>
        </el-tooltip>
        <div class="time">时间：{{ data.beginTime }}～{{ data.endTime }}</div>
      </div>
      <div class="No">编号：{{ data.code }}</div>
    </div>
    <div class="classNums">
      班级：{{ getClassNum(data.classNums) }}
      <span v-if="data.publishTeacherName" style="margin-left: 8px"
        >布置教师：{{ data.publishTeacherName }}</span
      >
      <span
        v-if="$route.query.password == 1"
        class="status"
        style="color: red; cursor: pointer"
        @click="toSheet(data)"
      >
        跳转答题卡
      </span>
    </div>
    <div class="footer">
      <div class="left">
        已录入：
        <el-popover
          ref="popover"
          v-model="showPoppver"
          placement="bottom"
          trigger="click"
          @show="showPoppverChange"
        >
          <div slot="reference" class="progress">
            <el-progress
              :percentage="rate2Percentage(data.rate)"
              style="width: 303px; margin-right: 10px"
              :stroke-width="10"
              :format="format"
            ></el-progress>
            {{ data.finishedCount }}/{{ data.sheetCount }}人
            <i
              :class="[
                'el-icon-caret-bottom',
                showPoppver ? 'rotate180' : 'rotate0',
              ]"
            ></i>
          </div>
          <div v-if="classNumData.length" class="progress-list">
            <div class="list-wrapper">
              <div
                v-for="(item, index) in classNumData"
                :key="index"
                class="progress-item"
              >
                <div class="classNum">{{ item.classNum }}</div>
                <el-progress
                  :percentage="rate2Percentage(item.rate)"
                  style="width: 303px; margin: 0 10px"
                  :stroke-width="10"
                  :format="format"
                ></el-progress>
                <div class="count">
                  {{ item.finishedCount }}/{{ item.sheetCount }}人
                </div>
              </div>
            </div>
          </div>
          <no-data v-else message="暂无数据" />
        </el-popover>
        <div
          v-if="data.statusScan > 0 && [1, 3].includes(roleId)"
          class="status"
          @click="toScan()"
        >
          <span>
            <img
              v-if="data.statusScan == 2"
              src="@/static/homework/abnormal_icon06@1x.png"
              alt=""
            />
            <img
              v-if="data.statusScan == 1"
              src="@/static/homework/normal_icon07@1x.png"
              alt=""
            />

            扫描跟踪
          </span>
        </div>
      </div>
      <div class="right">
        <downLoadHomework
          :student-sheet-url="data.studentSheetUrl"
          :teacher-sheet-url="data.teacherSheetUrl"
          :code="data.code"
        />
        <el-button :disabled="data.finishedCount == 0" @click="toReport"
          >作业报告</el-button
        >
        <el-button :disabled="data.finishedCount == 0" @click="toCommentary"
          >作业讲评</el-button
        >
        <el-dropdown @command="handleCommand">
          <el-button>
            <img src="@/static/homework/more_icon05@1x.png" alt="" />
            <img src="@/static/homework/more_icon05@1x.png" class="on" alt="" />
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="edit">修改作业信息</el-dropdown-item>
            <el-dropdown-item command="update">修正作业答案</el-dropdown-item>
            <el-dropdown-item command="delete">删除</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-dialog
      title="修改作业信息"
      :visible.sync="dialogVisible"
      width="600px"
      :before-close="handleClose"
    >
      <el-form
        ref="form"
        :model="form"
        label-position="right"
        label-width="100px"
        :rules="rules"
      >
        <el-form-item label="作业名称：" prop="homeworkName">
          <el-input
            v-model="form.homeworkName"
            maxlength="50"
            show-word-limit
            placeholder="请输入作业名称"
            class="text-nums"
          ></el-input>
        </el-form-item>
        <el-form-item label="布置对象：">
          <el-row>
            <el-col :span="11">
              <el-form-item label="学段：" label-width="65px">
                <el-select
                  v-model="form.level"
                  clearable
                  disabled
                  placeholder="全部"
                >
                  <el-option
                    v-for="item in levelOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="13">
              <el-form-item label="入学年份：" label-width="100px">
                <el-select
                  v-model="form.year"
                  clearable
                  disabled
                  placeholder="全部"
                >
                  <el-option
                    v-for="item in yearOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
        <div class="checkbox">
          <el-checkbox
            v-model="checkAll"
            :indeterminate="isIndeterminate"
            @change="handleCheckAllChange"
            >全选</el-checkbox
          >
          <div style="margin: 15px 0"></div>
          <el-checkbox-group v-model="classNums" @change="handleCheckClassNum">
            <el-checkbox
              v-for="item in classNumList"
              :key="item.id"
              :disabled="getDisabled(item)"
              :label="item.classNum"
              >{{ item.classNum }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
        <el-form-item label="作业时间：">
          <div class="timePicker">
            <el-date-picker
              v-model="form.beginTime"
              type="datetime"
              editable
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
            >
            </el-date-picker>
            <span>至</span>
            <el-date-picker
              v-model="form.endTime"
              type="datetime"
              editable
              format="yyyy-MM-dd HH:mm:ss"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="选择日期时间"
            >
            </el-date-picker>
          </div>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button :loading="confirmLoading" type="primary" @click="editSubmit"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <updateAnswer ref="updateAnswer"></updateAnswer>
  </div>
</template>
<script>
import { levelOptions } from "@/core/util/constdata";
import downLoadHomework from "./downLoadHomework";
import updateAnswer from "./updateAnswer";
import {
  homeworkClassRate,
  deleteHomeworkToTrash,
  updateHomework,
  homeworkClasNums,
  reportClasses,
} from "@/core/api/homework/index";
export default {
  name: "HomeworkItem",
  components: {
    downLoadHomework,
    updateAnswer,
  },
  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const nowYear = new Date().getFullYear();
    const arr = new Array(nowYear - 2014).fill("");
    let yearOptions = arr.map((item, index) => {
      return { label: index + 2015, value: index + 2015 };
    });
    return {
      showPoppver: false,
      isIndeterminate: false,
      checkAll: false,
      dialogVisible: false,
      classNums: [],
      classNumList: [],
      classNumData: [],
      loading: false,
      rules: {
        homeworkName: [
          { required: true, message: "请输入作业名称", trigger: "blur" },
        ],
      },
      old: [],
      form: {
        endTime: "",
        startTime: "",
        level: "",
        year: "",
        name: "",
        classNums: "",
      },
      levelOptions: levelOptions().map((item) => ({
        label: item.label,
        value: Number(item.value),
      })),
      roleId: null,
      confirmLoading: false,
      yearOptions: yearOptions.reverse(),
    };
  },
  created() {
    const userInfoString = localStorage.getItem("jzjx-userInfo");
    const userInfo = JSON.parse(userInfoString).content;
    this.roleId = userInfo.roleId;
  },
  methods: {
    getDisabled(it) {
      const classNum = this.old.find((item) => item.classNum == it.classNum);
      if (classNum) {
        return classNum.hasRecord == 1;
      }
      return false;
    },
    async getClassNumList() {
      const hasRes = await reportClasses({
        hwId: this.data.hwId,
      });
      const res = await homeworkClasNums({
        year: this.form.year,
        level: this.form.level,
      });
      this.old = hasRes.data.data;
      const allClassNum = res.data.data.map((item) => item.classNum);
      this.classNums = this.old
        .filter((item) => allClassNum.includes(item.classNum))
        .map((item) => item.classNum);
      this.classNumList = res.data.data;
      let checkedCount = this.classNums.length;
      this.checkAll = checkedCount === this.classNumList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.classNumList.length;
    },
    deleteItem() {
      this.$confirm("删除后可在回收站中恢复，确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await deleteHomeworkToTrash({ hwId: this.data.hwId });
          this.$message({
            message: "删除成功",
            type: "success",
            showClose: true,
          });
          this.$emit("del");
        })
        .catch(() => {
          this.$message({
            type: "info",
            showClose: true,
            message: "已取消删除",
          });
        });
    },
    async showPoppverChange() {
      this.classNumData = [];
      const res = await homeworkClassRate({ hwId: this.data.hwId });
      this.classNumData = res.data.data;
      this.$nextTick(() => {
        this.$refs.popover.updatePopper();
      });
    },
    getLevelName(level) {
      const enmu = {
        1: "小",
        2: "初",
        3: "高",
        4: "大",
      };
      if (level) {
        return enmu[level];
      }
      return "";
    },
    getClassNum(classNum) {
      if (!classNum) {
        return "";
      }
      const classNumList = classNum.split(",");
      if (classNumList.length > 5) {
        const classNumStr =
          classNumList.slice(0, 5).join(",") + `等${classNumList.length}个班级`;
        return classNumStr;
      } else {
        return classNum;
      }
    },
    handleCheckClassNum(val) {
      let checkedCount = val.length;
      this.checkAll = checkedCount === this.classNumList.length;
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.classNumList.length;
    },
    async editSubmit() {
      if (!this.classNums.length) {
        this.$message({
          type: "warning",
          message: "请至少选择一个班级",
          showClose: true,
        });
        return false;
      }
      try {
        this.confirmLoading = true;
        await updateHomework({
          ...this.form,
          name: this.form.homeworkName,
          levelId: this.form.level,
          id: this.form.hwId,
          classNums: this.classNums.join(","),
        });
        this.dialogVisible = false;
        this.$emit("update");
        this.$message({
          type: "success",
          message: "修改成功！",
          showClose: true,
        });
        this.confirmLoading = false;
      } catch {
        this.confirmLoading = false;
      }
    },
    toReport() {
      this.$router.push({
        path: "/homework/report",
        query: { hwId: this.data.hwId, scoreMode: this.data.scoreMode },
      });
    },
    toCommentary() {
      this.$router.push({
        path: "/homework/commentary",
        query: {
          hwId: this.data.hwId,
          year: this.data.year,
          level: this.data.level,
          subjectName: this.data.subjectName,
          homeworkName: this.data.homeworkName,
          subjectId: this.data.subjectId,
          scoreMode: this.data.scoreMode,
        },
      });
    },
    handleCheckAllChange(val) {
      this.checkAll = val;
      this.classNums = val
        ? [...this.classNumList.map((item) => item.classNum)]
        : this.old
            .filter((item) => item.hasRecord == 1)
            .map((item) => item.classNum);
      this.isIndeterminate = false;
    },
    rate2Percentage(rate) {
      if (rate) {
        return rate * 100;
      }
    },
    toScan() {
      const { href } = this.$router.resolve({
        path: "/homework/handleException",
        query: {
          hwId: this.data.hwId,
          errTypeId: 19,
          password: this.$route.query.password,
        },
      });
      let timer = setTimeout(() => {
        window.open(href, "_blank");
        clearTimeout(timer);
      }, 0);
    },
    toSheet(item) {
      const { href } = this.$router.resolve({
        name: "/homework/makeSheet",
        query: {
          uuid: item.sheetUuid,
          password: this.$route.query.password,
        },
      });
      let timer = setTimeout(() => {
        window.open(href, "_blank");
        clearTimeout(timer);
      }, 0);
    },
    format() {
      return "";
    },
    handleClose() {
      this.dialogVisible = false;
    },
    handleCommand(e) {
      if (e == "delete") {
        this.deleteItem();
      }
      if (e == "update") {
        this.$refs.updateAnswer.init(this.data);
      }
      if (e == "edit") {
        this.dialogVisible = true;
        this.form = { ...this.data };

        this.getClassNumList();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.HomeworkItem {
  box-sizing: border-box;
  padding: 18px;
  background-color: #fff;
  border: 1px solid #dee0e7;
  margin-bottom: 18px;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 18px;
    .title {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .subject {
        background-color: #2474ed;
        border-radius: 4px;
        color: #fff;
        height: 20px;
        padding: 0 6px;
        margin-right: 8px;
        max-width: 72px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      .year {
        background-color: #f0f4ff;
        border-radius: 4px;
        color: #56575d;
        height: 20px;
        padding: 0 6px;
        margin-right: 14px;
      }
      .name {
        color: #0a1119;
        font-size: 16px;
        margin-right: 8px;
        font-weight: 500;
        max-width: 420px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
    .No {
      font-size: 14px;
      color: #3e4551;
    }
  }

  .classNums {
    color: #0a1119;
    font-size: 14px;
    margin-bottom: 18px;
  }
  .footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex: 1;
      white-space: nowrap;
      .status {
        margin-left: 20px;
        cursor: pointer;
      }
    }
    .right {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      text-align: right;
      flex: 1;
    }
    .progress {
      display: flex;
      align-items: center;
    }
  }
  .rotate180 {
    transform: rotate(180deg);
    transition: transform 0.3s linear;
    cursor: pointer;
  }
  .rotate0 {
    transform: rotate(0deg);
    transition: transform 0.3s linear;
    cursor: pointer;
  }
}
.progress-list {
  max-height: 320px;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 10px; /* 滚动条宽度 */
    background-color: #f9f9f9; /* 滚动条背景色 */
  }
  &::-webkit-scrollbar-thumb {
    background-color: #bdbdbd; /* 滑块颜色 */
    border-radius: 10px; /* 滑块圆角 */
  }

  /* 自定义滚动条轨道样式 */
  &::-webkit-scrollbar-track {
    background-color: #f0f0f0; /* 轨道颜色 */
  }
  .list-wrapper {
    display: table;
    border-spacing: 10px;

    .progress-item {
      display: table-row;
      .classNum {
        display: table-cell;
        text-align: right;
      }
      .count {
        display: table-cell;
      }
    }
    .progress-item + .progress-item {
      margin-top: 24px;
    }
  }
}
.el-button + .el-dropdown {
  margin-left: 10px;
}
.checkbox {
  margin-bottom: 22px;
  box-sizing: border-box;
  padding: 0 16px;
}
.timePicker {
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    margin: 0 10px;
  }
}
.el-dialog .el-dialog__body .el-form-item {
  padding: 0px;
}
</style>
