<template>
  <div class="filter">
    <div class="selects">
      <div class="selects-item">
        学段：
        <el-select
          v-model="form.level"
          style="width: 100px"
          clearable
          placeholder="全部"
          @change="levelChange"
        >
          <el-option
            v-for="item in levelOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="selects-item">
        入学年份：
        <el-select
          v-model="form.year"
          style="width: 100px"
          clearable
          placeholder="全部"
          @change="filterChange"
        >
          <el-option
            v-for="item in yearOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="selects-item">
        学科：
        <el-select
          v-model="form.subjectId"
          style="width: 100px"
          clearable
          placeholder="全部"
          @change="filterChange"
        >
          <el-option
            v-for="item in subjectList"
            :key="item.subjectId"
            :label="item.subjectName"
            :value="item.subjectId"
          >
          </el-option>
        </el-select>
      </div>
      <div class="selects-item">
        班级：
        <el-select
          v-model="form.classNums"
          clearable
          multiple
          collapse-tags
          placeholder="全部"
          @change="classNumsChange"
        >
          <el-option
            v-for="item in classNumList"
            :key="item.id"
            :label="item.classNum"
            :value="item.classNum"
          >
          </el-option>
        </el-select>
      </div>

      <div class="selects-item">
        时间
        <el-select
          v-model="form.time"
          style="width: 100px"
          clearable
          placeholder="全部"
          @change="filterChange"
        >
          <el-option
            v-for="item in timeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="input">
      <el-input
        v-model="form.keyword"
        placeholder="输入作业编号或名称"
        style="width: 180px"
        clearable
        @keyup.enter.native="filterChange"
        @change="filterChange"
      >
        <i
          slot="suffix"
          class="el-icon-search el-input__icon"
          @click="filterChange"
        >
        </i>
      </el-input>
    </div>
  </div>
</template>
<script>
import {
  homeworkClasNums,
  homeworkSubject,
  levelyear,
} from "@/core/api/homework/index";
import { mapGetters } from "vuex";
import {
  initYearOtions,
  initLevelOptions,
} from "@/views/studentsHomework/func";
import jsCookie from "js-cookie";
export default {
  name: "HomeFilter",
  data() {
    const nowYear = new Date().getFullYear();
    const arr = new Array(10).fill("");
    let yearOptions = arr.map((item, index) => {
      return { label: nowYear - index, value: nowYear - index };
    });
    return {
      form: {
        level: "",
        classNums: [],
        year: "",
        subjectId: "",
        time: "",
        keyword: "",
      },
      levelOptions: [],
      yearOptions: [],
      classNumList: [],
      subjectList: [],
      levelYearList: [],
      timeOptions: yearOptions,
      searchData: {},
      userInfo: {},
    };
  },
  computed: {
    ...mapGetters({ getLevelId: "getLevelId", getSubjectId: "getSubjectId" }),
  },
  created() {
    const userInfoString = localStorage.getItem("jzjx-userInfo");
    this.userInfo = JSON.parse(userInfoString).content;
    const cookieStr = jsCookie.get(
      `homeworkFilterParams-${this.userInfo.userId}`
    );
    if (cookieStr) {
      this.searchData = { ...JSON.parse(cookieStr) };
      if (this.searchData.pageSize) {
        this.form.pageSize = this.searchData.pageSize;
        this.form.current = this.searchData.current;
      }
      this.form.time = this.searchData.time;
      this.form.year = this.searchData.year;
      this.form.subjectId = this.searchData.subjectId;
      this.form.keyword = this.searchData.keyword;
    } else if (this.userInfo.teacherRoleList) {
      const teacherRole = this.userInfo.teacherRoleList.find(
        (item) => item.type == 1
      );
      if (teacherRole) {
        this.form.subjectId = teacherRole.subjectId;
        this.form.year = teacherRole.year;
      }
    }
    this.getlevelyear();
  },

  methods: {
    async getlevelyear() {
      const res = await levelyear();
      this.levelYearList = res.data.data;
      initLevelOptions.bind(this)();
    },

    filterChange() {
      if (this.form.year && this.form.level) {
        this.getClassNumList("change");
      } else {
        this.form.classNumsList = [];
      }

      initYearOtions.bind(this)(this.form.level);
      this.$emit("change", { ...this.form, current: 1 });
    },
    classNumsChange() {
      this.$emit("change", { ...this.form, current: 1 });
    },
    levelChange() {
      if (this.form.year && this.form.level) {
        this.getClassNumList();
      } else {
        this.form.classNumsList = [];
      }
      this.form.subjectId = null;
      this.getSubjectList();
      this.$emit("change", { ...this.form, current: 1 });
    },
    async getClassNumList(type) {
      const res = await homeworkClasNums({
        year: this.form.year,
        level: this.form.level,
      });
      this.classNumList = res.data.data;
      if (type == "change") {
        this.form.classNums = [];
      } else {
        const classNums = res.data.data.map((item) => item.classNum);
        if (this.searchData && this.searchData.classNums) {
          const result = this.searchData.classNums.filter((item) =>
            classNums.includes(item)
          );
          this.form.classNums = result;
        } else if (this.userInfo.teacherRoleList) {
          const teacherRole = this.userInfo.teacherRoleList.find(
            (item) => item.type == 1
          );
          if (teacherRole) {
            const caches = teacherRole.classNums.split(",");
            const result = caches.filter((item) => classNums.includes(item));
            this.form.classNums = result;
          }
        }
      }
      this.$emit("change", this.form);
    },
    async getSubjectList() {
      const res = await homeworkSubject({ level: this.form.level });
      this.subjectList = res.data.data;
    },
  },
};
</script>
<style lang="scss" scoped>
.filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  .selects {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    &-item {
      margin-right: 18px;
    }
  }
}
</style>
