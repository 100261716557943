<template>
  <div class="downloadStatistics">
    <el-dialog
      title="下载作业统计数据"
      :visible.sync="dialogVisible"
      width="400px"
      :close-on-click-modal="false"
    >
      <div class="from-item">
        <div class="label">时间：</div>
        <el-date-picker
          v-model="form.timeArr"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          format="yyyy-MM-dd"
          value-format="yyyy-MM-dd"
        >
        </el-date-picker>
      </div>
      <div class="from-item">
        <div class="label">学段：</div>
        <el-select
          v-model="form.level"
          clearable
          placeholder="全部学段"
          @change="levelChange()"
        >
          <el-option
            v-for="item in levelOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="from-item">
        <div class="label">学年：</div>
        <el-select v-model="form.year" clearable placeholder="全部学年">
          <el-option
            v-for="item in yearOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="from-item">
        <div class="label">学科：</div>
        <el-select v-model="form.subjectId" clearable placeholder="全部科目">
          <el-option
            v-for="item in subjectOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="downFile()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { filterSubject, getYear } from "@/core/util/util";
import { levelOptions } from "@/core/util/constdata";
import { downloadStatHomework } from "@/core/api/homework/index";
export default {
  name: "DownloadStatistics",
  data() {
    return {
      dialogVisible: false,
      btnLoading: false,
      levelOptions: levelOptions(),
      yearOptions: getYear(),
      subjectOptions: filterSubject(),
      form: {
        timeArr: [],
        level: "",
        year: "",
        subjectId: "",
      },
    };
  },
  created() {},
  methods: {
    levelChange() {
      this.form.subjectId = "";
      this.subjectOptions = filterSubject(this.form.level);
    },
    init() {
      this.dialogVisible = true;
    },
    downFile() {
      if (!this.form.timeArr || this.form.timeArr.length == 0) {
        this.$message({
          showClose: true,
          message: "请选择导出时间段",
          type: "warning",
        });
        return;
      }
      this.btnLoading = true;
      let data = JSON.parse(JSON.stringify(this.form));
      data.startTime = data.timeArr[0];
      data.endTime = data.timeArr[1];
      delete data.timeArr;
      downloadStatHomework(data)
        .then((res) => {
          let { url, name } = res.data.data;
          const { href } = this.$router.resolve({
            path: "/downFile",
            query: {
              url: url,
              name: name,
              type: 1,
            },
          });
          this.btnLoading = false;
          this.dialogVisible = false;
          let timer = setTimeout(() => {
            window.open(href, "_blank");
            clearTimeout(timer);
          }, 0);
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.downloadStatistics {
  .from-item {
    display: flex;
    align-items: center;
    margin-bottom: 18px;
    .el-select {
      width: 100%;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .label {
      flex-shrink: 0;
    }
    ::v-deep i {
      line-height: 26px;
    }
  }
}
</style>
