<template>
  <div class="updateAnswer">
    <el-dialog
      title="修正答案"
      :visible.sync="dialogVisible"
      :close-on-click-modal="false"
      width="738px"
    >
      <div class="title">{{ indexObj.homeworkName }}</div>
      <div class="tips-box">
        <div class="tips">{{ indexObj.subjectName }}</div>
        <div class="sub-tips">
          {{ levelArr[indexObj.level - 1] }}{{ indexObj.year }}级
        </div>
        <div class="time">
          时间：{{ indexObj.beginTime }}～{{ indexObj.endTime }}
        </div>
      </div>
      <div class="error-tips">
        <img src="@/assets/uExam/icon_waring.png" alt="" />
        仅针对客观题答案设置错误情况，修正答案后会重新判断学生题目对错以及更新作业数据！
      </div>
      <div class="question-list-box edit-scroll-style">
        <div
          v-for="(item, index) in questionList"
          :key="index"
          class="question-list"
        >
          {{ item.questionNum }}
          <div class="edit-btn" @click="checkItem(item, -1)">
            <i class="el-icon-minus"></i>
          </div>
          <div class="edit-btn" @click="checkItem(item, 1)">
            <i class="el-icon-plus"></i>
          </div>
          <template v-for="(bItem, BIndex) in optionArr">
            <div
              v-if="item.optionCount > BIndex"
              :key="BIndex"
              class="options-item"
              :class="{
                'options-item-on': item.answer.indexOf(bItem) != -1,
              }"
              @click="checkAnswer(item, bItem)"
            >
              {{ bItem }}
            </div>
          </template>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="btnLoading" @click="subModel()">
          确 定
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getAnswerList, upateAnswerList } from "@/core/api/homework/sheet";
const optionStr = "ABCDEFGHIJKLMNOPQRSTVWXYZ";
const levelArr = ["小", "初", "高", "大"];
export default {
  name: "UpdateAnswer",
  data() {
    return {
      optionArr: optionStr.split(""),
      questionList: [],
      dialogVisible: false,
      btnLoading: false,
      indexObj: {},
      levelArr: levelArr,
    };
  },
  created() {},
  methods: {
    async getList() {
      let data = {
        uuid: this.indexObj.sheetUuid,
      };
      await getAnswerList(data).then((res) => {
        if (res.data.data) {
          this.questionList = res.data.data.map((item) => {
            item.answer = item.answer ? item.answer.split("") : [];
            return item;
          });
        }
      });
    },
    subModel() {
      this.$confirm(
        "修正答案后会重新判断学生题目对错，请确认当前答案设置正确。",
        "温馨提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          this.submitEvent();
        })
        .catch(() => {});
    },
    submitEvent() {
      let data = JSON.parse(JSON.stringify(this.questionList));
      let array = [];
      data = data.map((item) => {
        item.answer = item.answer.sort();
        item.answer = item.answer.join("");
        if (!item.answer) {
          array.push(item.questionNum);
        }
        return item;
      });
      if (array.length > 0) {
        this.$message({
          message: `第${array.join(",")}题答案未设置!`,
          type: "warning",
          showClose: true,
        });
        return;
      }
      this.subItem(data);
    },
    subItem(data) {
      this.btnLoading = true;
      upateAnswerList(data)
        .then(() => {
          this.dialogVisible = false;
          this.btnLoading = false;
          this.$message({
            message: `修正成功!`,
            type: "success",
            showClose: true,
          });
        })
        .catch(() => {
          this.btnLoading = false;
        });
    },
    async init(data) {
      this.indexObj = JSON.parse(JSON.stringify(data));
      await this.getList();
      this.dialogVisible = true;
    },
    checkItem(data, type) {
      if (data.optionCount == 8 && type == 1) {
        this.$message({
          message: "选项最多8个",
          type: "warning",
          showClose: true,
        });
        return;
      }
      if (data.optionCount == 2 && type == -1) {
        this.$message({
          message: "选项最少2个",
          type: "warning",
          showClose: true,
        });
        return;
      }
      data.optionCount += type;
    },
    checkAnswer(item, bItem) {
      if (item.answer.indexOf(bItem) != -1) {
        item.answer = item.answer.filter((index) => index != bItem);
      } else {
        item.answer.push(bItem);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.updateAnswer {
  .question-list-box {
    padding: 18px;
    max-height: 368px;
    width: 100%;
    border: 1px solid #dee0e7;
    .question-list {
      display: flex;
      align-items: center;
      margin-bottom: 18px;
      .edit-btn {
        width: 32px;
        height: 32px;
        background: #f5f7fa;
        border-radius: 4px;
        border: 1px solid #d5d6db;
        text-align: center;
        line-height: 32px;
        margin-left: 8px;
        cursor: pointer;
      }
      .options-item {
        cursor: pointer;
        width: 46px;
        height: 32px;
        background: #ffffff;
        border-radius: 4px;
        border: 1px solid #d5d6db;
        text-align: center;
        line-height: 32px;
        margin-left: 8px;
      }
      .options-item-on {
        background: #2474ed;
        border: 1px solid #2474ed;
        color: #ffffff;
      }
    }
  }
  .error-tips {
    background: #fff3f3;
    border-radius: 4px;
    padding: 10px 18px;
    margin: 18px 0;
    line-height: 1;
    img {
      vertical-align: text-bottom;
      margin-right: 8px;
    }
  }
  .title {
    margin-bottom: 18px;
    font-size: 16px;
    color: #0a1119;
  }
  .tips-box {
    display: flex;
    align-items: center;
    .tips {
      background: #2474ed;
      border-radius: 4px;
      line-height: 1.2;
      padding: 4px 6px;
      font-size: 12px;
      color: #ffffff;
    }
    .sub-tips {
      font-size: 12px;
      color: #56575d;
      background: #f0f4ff;
      border-radius: 4px;
      line-height: 1.2;
      padding: 4px 6px;
    }
    > div {
      margin-right: 8px;
    }
  }
}
</style>
