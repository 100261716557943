<template>
  <div class="downLoadHomework">
    <el-button @click="handleClick">下载作业</el-button>
    <el-dialog
      title="下载作业"
      :visible.sync="dialogVisible"
      width="28%"
      :before-close="handleClose"
    >
      <div class="radios">
        <div
          v-for="(item, index) in radiosList"
          :key="index"
          :class="['radio', value == index ? 'active' : '']"
          @click="handleRadio(index)"
        >
          <div class="title">{{ item.title }}</div>
          <div class="message">{{ item.message }}</div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" :loading="loading" @click="downloadFile"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { downloadHomework } from "@/core/api/homework/index";
export default {
  name: "DownLoadHomework",
  props: {
    studentSheetUrl: {
      type: [String, null],
      default: null,
    },
    teacherSheetUrl: {
      type: [String, null],
      default: null,
    },
    code: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      dialogVisible: false,
      loading: false,
      radiosList: [
        {
          title: "学生作业卡",
          message: "学生作答题卡，无答案解析",
        },
        {
          title: "教师用卷",
          message: "答案解析在每题后",
        },
      ],
      value: 0,
    };
  },
  created() {},
  methods: {
    async downloadFile() {
      this.loading = true;
      try {
        const res = await downloadHomework({
          // urls: url,
          code: this.code,
          type: this.value + 1,
        });
        const { href } = this.$router.resolve({
          path: "/downFile",
          query: {
            url: res.data.data.url,
            name:
              res.data.data.name ||
              (this.value == 1 ? "教师用卷" : "学生作业卡"),
            type: 1,
          },
        });
        this.loading = false;
        let timer = setTimeout(() => {
          window.open(href, "_blank");
          this.loading = false;
          clearTimeout(timer);
        }, 0);
      } catch {
        this.loading = false;
      }
    },
    handleClick() {
      this.dialogVisible = true;
    },
    handleRadio(index) {
      this.value = index;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.downLoadHomework {
  margin-right: 10px;
}
.radios {
  display: flex;
  align-items: center;
  justify-content: space-between;
  .radio {
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #dee0e7;
    box-sizing: border-box;
    padding: 20px 24px;
    height: 80px;
    text-align: center;
    font-size: 14px;
    flex: 1;
    cursor: pointer;
    .title {
      color: #0a1119;
      font-weight: 500;
      margin-bottom: 4px;
    }
    .message {
      color: #3e4551;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .active {
    background: #fafcff;
    border-color: #2474ed !important;
    .title {
      color: #2474ed;
    }
    .message {
      color: #2474ed;
    }
  }
  .radio + .radio {
    margin-left: 18px;
  }
}
::v-deep .el-dialog__header {
  text-align: left;
}
</style>
