var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dialog',{attrs:{"title":"上传试卷","visible":_vm.dialogVisible,"width":"650px","before-close":_vm.handleClose},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"subject-box"},[_c('img',{attrs:{"src":require("@/static/resHeader/kemu.png"),"alt":""}}),_vm._v(" "+_vm._s(_vm.levelName)+_vm._s(_vm.subjectName)+" "),_c('i',{staticClass:"el-icon-arrow-down"}),_c('div',{staticClass:"subject-box-hover"},[_c('i',{staticClass:"el-icon-caret-top"}),_vm._l((_vm.levelOptions),function(v,i){return [(v.value != 4)?_c('div',{key:i,staticClass:"hover-list"},[_c('p',[_vm._v(_vm._s(v.label))]),_c('div',{staticClass:"hover-list-label",class:{ 'hover-list-label-1': v.value == 1 }},[(v.value == 3)?_vm._l((_vm.highSubjectOptions),function(item,key){return _c('span',{key:key + 'km',staticClass:"subject-item",class:{
                  on: item.value == _vm.subjectId && v.value == _vm.levelId,
                },on:{"click":function($event){return _vm.subjectClick(v, item)}}},[_vm._v(" "+_vm._s(item.label)+" ")])}):_vm._e(),(v.value == 2)?_vm._l((_vm.juniorSubjectOptions),function(item,key){return _c('span',{key:key + 'km',staticClass:"subject-item",class:{
                  on: item.value == _vm.subjectId && v.value == _vm.levelId,
                },on:{"click":function($event){return _vm.subjectClick(v, item)}}},[_vm._v(" "+_vm._s(item.label)+" ")])}):_vm._e(),(v.value == 1)?_vm._l((_vm.primarySubjectOptions),function(item,key){return _c('span',{key:key + 'km',staticClass:"subject-item",class:{
                  on: item.value == _vm.subjectId && v.value == _vm.levelId,
                },on:{"click":function($event){return _vm.subjectClick(v, item)}}},[_vm._v(" "+_vm._s(item.label)+" ")])}):_vm._e()],2)]):_vm._e()]})],2)]),_c('div',{staticClass:"error-tip"},[_c('img',{attrs:{"src":require("../../../../assets/imgs/exam/check.png"),"alt":""}}),_vm._v(" 文件仅支持.docx，请勿手动将文件格式后缀修改为.docx，否则将解析失败！ ")]),_c('div',{staticClass:"tips"},[_vm._v(" 按模板编制试卷，然后上传，实现智能导入。 "),_c('el-link',{staticStyle:{"text-decoration":"underline"},attrs:{"type":"primary","underline":""},on:{"click":_vm.downloadTemplate}},[_vm._v("下载word模板")])],1),_c('el-upload',{ref:"elUpload",staticClass:"upload-demo",staticStyle:{"margin-bottom":"18px","width":"100%"},attrs:{"action":_vm.uploadAvatarURL,"headers":_vm.uploadHeader,"file-list":_vm.fileList,"on-success":_vm.uploadSuccess,"on-error":_vm.uploadError,"on-change":_vm.uploadChange,"on-remove":_vm.uploadRemove,"name":"file","auto-upload":false,"accept":".doc,.docx","drag":""}},[_c('i',{staticClass:"el-icon-upload"}),_c('div',{staticClass:"el-upload__text"},[_vm._v("将.docx文件点击或拖拽到这里上传")])]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary","loading":_vm.loading,"disabled":_vm.fileList.length == 0},on:{"click":_vm.submit}},[_vm._v("上 传")])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }