<template>
  <div v-loading="loading" class="homeWorkList">
    <div class="wrapper">
      <HomeworkHeader />
      <div class="homeList">
        <HomeFilter @change="filterChange" />
        <div v-if="tableData.length" class="contentList">
          <HomeworkItem
            v-for="(item, index) in tableData"
            :key="index"
            :data="item"
            @update="getData"
            @del="deleteItem"
          />
        </div>
        <no-data v-else message="暂无作业信息！"></no-data>
        <el-pagination
          v-if="tableData.length"
          :current-page="current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import HomeworkHeader from "./components/HomeworkHeader";
import HomeFilter from "./components/HomeFiler.vue";
import HomeworkItem from "./components/HomeworkItem";
import { homeworkPage } from "@/core/api/homework/index";
import jsCookie from "js-cookie";
export default {
  name: "HomeWorkList",
  components: {
    HomeworkHeader,
    HomeFilter,
    HomeworkItem,
  },
  data() {
    return {
      total: 0,
      current: 1,
      pageSize: 10,
      tableData: [],
      searchForm: {},
      loading: false,
      userId: null,
      scrollTopVal: 0,
      hasScrollTo: false,
    };
  },
  created() {
    const userInfoString = localStorage.getItem("jzjx-userInfo");
    const userInfo = JSON.parse(userInfoString).content;
    this.userId = userInfo.userId;

    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.handleScroll, true);
    sessionStorage.setItem("scrollTopVal", this.scrollTopVal);
  },
  methods: {
    handleScroll() {
      this.scrollTopVal =
        document.documentElement.scrollTop || document.body.scrollTop;
    },

    filterChange(search) {
      if (search) {
        this.searchForm = { ...search };
        if (search.pageSize && search.current) {
          this.current = search.current;
          this.pageSize = search.pageSize;
        }
      }
      jsCookie.set(
        `homeworkFilterParams-${this.userId}`,
        JSON.stringify({
          ...this.searchForm,
          pageSize: this.pageSize,
          current: this.current,
        }),
        { expires: 90 }
      );
      this.getData();
    },
    deleteItem() {
      if (this.current > 1 && this.tableData.length == 1) {
        this.current--;
      }
      this.getData();
    },
    async getData() {
      try {
        this.loading = true;
        const res = await homeworkPage({
          ...this.searchForm,
          classNums: this.searchForm.classNums
            ? this.searchForm.classNums.join(",")
            : "",
          current: this.current,
          size: this.pageSize,
        });
        if (
          this.current * this.pageSize > res.data.data.total &&
          res.data.data.records.length == 0 &&
          this.current > 1
        ) {
          this.current = 1;
          this.getData();
        } else {
          this.tableData = res.data.data.records;
          this.total = res.data.data.total;
          if (!this.hasScrollTo) {
            this.$nextTick(() => {
              this.hasScrollTo = true;
              this.scrollTopVal = sessionStorage.getItem("scrollTopVal");
              window.scrollTo({
                top: this.scrollTopVal,
              });
            });
          }
          this.loading = false;
        }
      } catch {
        this.loading = false;
      }
    },

    handleSizeChange(pageSize) {
      this.pageSize = pageSize;
      jsCookie.set(
        `homeworkFilterParams-${this.userId}`,
        JSON.stringify({
          ...this.searchForm,
          pageSize: this.pageSize,
          current: this.current,
        }),
        { expires: 90 }
      );
      this.getData();
    },
    handleCurrentChange(current) {
      this.current = current;
      jsCookie.set(
        `homeworkFilterParams-${this.userId}`,
        JSON.stringify({
          ...this.searchForm,
          pageSize: this.pageSize,
          current: this.current,
        }),
        { expires: 90 }
      );
      this.getData();
    },
  },
};
</script>
<style lang="scss" scoped>
.homeWorkList {
  margin-bottom: 18px;
  .wrapper {
    width: 1200px;
    .homeList {
      box-sizing: border-box;
      padding: 18px;
      background: #ffffff;
    }
    .contentList {
    }
  }
}
</style>
