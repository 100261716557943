<template>
  <div class="HomeworkHeader">
    <el-dropdown trigger="click" @command="dropChange">
      <el-button type="primary">
        <i class="el-icon-circle-plus-outline el-icon--left"></i>布置作业
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item command="1">文通云资源</el-dropdown-item>
        <el-dropdown-item command="2">学科网资源</el-dropdown-item>
        <el-dropdown-item command="3">校本作业</el-dropdown-item>
        <el-dropdown-item command="4">我的作业</el-dropdown-item>
        <el-dropdown-item command="5">上传作业</el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
    <div class="btns">
      <div v-if="initStore()" class="btn" @click="showFile()">
        <img src="@/static/homework/icon-down-file.png" alt="" />
        作业统计
      </div>
      <div class="btn" @click="handleClick">
        <img src="@/static/homework/scanning operation_icon02@1x.png" alt="" />
        扫描作业
      </div>
      <div class="btn" @click="toRecycle">
        <img src="@/static/homework/recycle bin_icon03@1x.png" alt="" />
        回收站
      </div>
      <downloadStatistics ref="downloadStatistics"></downloadStatistics>
    </div>

    <Upload ref="upload" />
    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="25%"
      :before-close="handleClose"
    >
      <div class="tip">
        <i class="el-icon-error"></i>
        <div>
          「文通云扫描客户端」启动失败，请检查是否安装，若还未安装，请点击<el-button
            type="text"
            @click="downloadClient"
            >立即下载</el-button
          >安装。
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { downClient } from "@/core/util/util.js";
import Upload from "./upload.vue";
import downloadStatistics from "./downloadStatistics.vue";
import { getStore } from "@/core/util/store";
export default {
  name: "HomeworkHeader",
  components: {
    Upload,
    downloadStatistics,
  },
  data() {
    return {
      dialogVisible: false,
      isPc: false,
    };
  },
  created() {
    this.checkDevice();
  },
  methods: {
    initStore() {
      let { roleId, teacherRoleType } = getStore({ name: "userInfo" });
      let type = false;
      if (roleId == 5) {
        let strArr = ["3", "4", "5"];
        strArr.map((item) => {
          if (teacherRoleType.includes(item)) {
            type = true;
          }
        });
      } else {
        type = true;
      }
      return type;
    },
    checkDevice() {
      // 获取用户代理字符串
      const userAgent = navigator.userAgent;

      // 判断是否为PC设备
      this.isPC =
        !/Mobile|Android|iP(ad|hone)|IEMobile|BlackBerry|Opera Mini|Opera Mobi|webOS/i.test(
          userAgent
        );
    },
    downloadClient: downClient,
    toRecycle() {
      this.$router.push({ path: "/homework/recycle" });
    },
    handleClose() {
      this.dialogVisible = false;
    },
    openUriWithInputTimeoutHack(uri, failCb, successCb) {
      let target = document.createElement("input");
      target.style.width = "0";
      target.style.height = "0";
      target.style.position = "fixed";
      target.style.top = "0";
      target.style.left = "0";
      document.body.appendChild(target);

      target.focus();
      var handler = this._registerEvent(target, "blur", onBlur);
      function onBlur() {
        successCb && successCb();
        handler.remove();
        clearTimeout(timeout);
        document.body.removeChild(target);
      }

      //will trigger onblur
      location.href = uri;

      // Note: timeout could vary as per the browser version, have a higher value
      var timeout = setTimeout(function () {
        failCb && failCb();
        handler.remove();
        document.body.removeChild(target);
      }, 1000);
    },
    _registerEvent(target, eventType, cb) {
      if (target.addEventListener) {
        target.addEventListener(eventType, cb);
        return {
          remove: function () {
            target.removeEventListener(eventType, cb);
          },
        };
      } else {
        target.attachEvent(eventType, cb);
        return {
          remove: function () {
            target.detachEvent(eventType, cb);
          },
        };
      }
    },
    showFile() {
      this.$refs.downloadStatistics.init();
    },
    handleClick() {
      if (!this.isPC) {
        this.$message({
          type: "warning",
          message: "当前设备不是PC端，请使用PC端打开",
          showClose: true,
        });
        return false;
      }
      const appUrl = "WenTongScanner://";
      this.openUriWithInputTimeoutHack(
        appUrl,
        () => {
          this.dialogVisible = true;
        },
        () => {
          // 浏览器弹窗提示
          //  todo
        }
      );
    },
    dropChange(key) {
      if (key == "1") {
        this.$router.push({ path: "/res/knowledgePointsPaper" });
      } else if (key == "2") {
        this.$router.push({ path: "/res/xuekewang" });
      } else if (key == "3") {
        this.$router.push({
          path: "/res/schoolResource",
          query: { currentTypeId: 3 },
        });
      } else if (key == "4") {
        this.$router.push({
          path: "/homework/myHomework",
        });
      } else if (key == "5") {
        this.$refs.upload.init();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.HomeworkHeader {
  box-sizing: border-box;
  height: 64px;
  padding: 0 18px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 16px 0;
  background-color: #fff;
  .btns {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .btn {
      cursor: pointer;
      color: #0a1119;
      font-size: 14px;
      vertical-align img {
        margin-right: 2px;
      }
    }
    .btn + .btn {
      margin-left: 26px;
    }
  }
}
.tip {
  box-sizing: border-box;
  padding-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: 38px;
  i {
    color: red;
    font-size: 28px;
    margin-left: 4px;
  }
  .el-button {
    text-decoration: underline;
  }
}
.el-dropdown-menu__item {
  padding: 6px 14px;
}
</style>
