<template>
  <el-dialog
    title="上传试卷"
    :visible.sync="dialogVisible"
    width="650px"
    :before-close="handleClose"
  >
    <div class="subject-box">
      <img src="@/static/resHeader/kemu.png" alt="" />
      {{ levelName }}{{ subjectName }}
      <i class="el-icon-arrow-down"></i>
      <div class="subject-box-hover">
        <i class="el-icon-caret-top"></i>
        <template v-for="(v, i) in levelOptions">
          <div v-if="v.value != 4" :key="i" class="hover-list">
            <p>{{ v.label }}</p>
            <div
              class="hover-list-label"
              :class="{ 'hover-list-label-1': v.value == 1 }"
            >
              <template v-if="v.value == 3">
                <span
                  v-for="(item, key) in highSubjectOptions"
                  :key="key + 'km'"
                  class="subject-item"
                  :class="{
                    on: item.value == subjectId && v.value == levelId,
                  }"
                  @click="subjectClick(v, item)"
                >
                  {{ item.label }}
                </span>
              </template>
              <template v-if="v.value == 2">
                <span
                  v-for="(item, key) in juniorSubjectOptions"
                  :key="key + 'km'"
                  class="subject-item"
                  :class="{
                    on: item.value == subjectId && v.value == levelId,
                  }"
                  @click="subjectClick(v, item)"
                >
                  {{ item.label }}
                </span>
              </template>
              <template v-if="v.value == 1">
                <span
                  v-for="(item, key) in primarySubjectOptions"
                  :key="key + 'km'"
                  class="subject-item"
                  :class="{
                    on: item.value == subjectId && v.value == levelId,
                  }"
                  @click="subjectClick(v, item)"
                >
                  {{ item.label }}
                </span>
              </template>
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="error-tip">
      <img src="../../../../assets/imgs/exam/check.png" alt="" />
      文件仅支持.docx，请勿手动将文件格式后缀修改为.docx，否则将解析失败！
    </div>
    <div class="tips">
      按模板编制试卷，然后上传，实现智能导入。
      <el-link
        type="primary"
        underline
        style="text-decoration: underline"
        @click="downloadTemplate"
        >下载word模板</el-link
      >
    </div>
    <el-upload
      ref="elUpload"
      class="upload-demo"
      :action="uploadAvatarURL"
      :headers="uploadHeader"
      :file-list="fileList"
      :on-success="uploadSuccess"
      :on-error="uploadError"
      :on-change="uploadChange"
      :on-remove="uploadRemove"
      style="margin-bottom: 18px; width: 100%"
      name="file"
      :auto-upload="false"
      accept=".doc,.docx"
      drag
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text">将.docx文件点击或拖拽到这里上传</div>
    </el-upload>

    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button
        type="primary"
        :loading="loading"
        :disabled="fileList.length == 0"
        @click="submit"
        >上 传</el-button
      >
    </span>
  </el-dialog>
</template>
<script>
import { levelOptions, subjectOptions } from "@/core/util/constdata.js";
import { getStore } from "@/core/util/store";
import { filterSubject } from "@/core/util/util";
import { templateModel } from "@/core/api/homework/index";
export default {
  name: "UploadHomework",
  data() {
    return {
      dialogVisible: false,
      levelOptions: levelOptions().filter((item) => item.value != 4),
      subjectOptions: subjectOptions,
      juniorSubjectOptions: [],
      highSubjectOptions: [],
      primarySubjectOptions: [],
      levelName: "",
      subjectName: "",
      subjectId: 1,
      levelId: 2,
      isOver: false,
      notWord: false,
      fileObj: {},
      fileList: [],
      uploadAvatarURL: "",
      loading: false,
      uploadHeader: {
        Authorization: "Bearer " + getStore({ name: "access_token" }),
        "SCHOOL-ID": getStore({ name: "school_id" }),
      },
    };
  },
  created() {
    this.initBaseData();
  },
  methods: {
    initBaseData() {
      this.highSubjectOptions = filterSubject(3);
      this.juniorSubjectOptions = filterSubject(2);
      this.primarySubjectOptions = [
        {
          value: 1,
          label: "语文",
          index: 1,
        },
        {
          value: 2,
          label: "数学",
          index: 2,
        },
        {
          value: 3,
          label: "英语",
          index: 3,
        },
      ];
      const subjectLevelObj = [
        {
          level: 1,
          options: this.primarySubjectOptions,
        },
        {
          level: 2,
          options: this.juniorSubjectOptions,
        },
        {
          level: 3,
          options: this.highSubjectOptions,
        },
      ];
      const levels = this.levelOptions.map((item) => Number(item.value));
      const caches = localStorage.getItem("jzjx-userInfo");
      const storage = localStorage.getItem("homeworkUpload");
      if (caches) {
        if (storage) {
          const data = JSON.parse(storage);
          this.levelName = data.level.label;
          this.subjectName = data.subject.label;
          this.levelId = data.level.value;
          this.subjectId = data.subject.value;
        } else {
          const userInfo = JSON.parse(caches).content;
          const teacherRoleList = userInfo.teacherRoleList;
          if (teacherRoleList) {
            const teacherRoleItem = teacherRoleList.find(
              (item) => item.type == 1
            );
            if (teacherRoleItem) {
              const noneEmptyArray = subjectLevelObj.find(
                (item) =>
                  item.options.length > 0 && item.level == teacherRoleItem.level
              );
              const subjectObj = noneEmptyArray.options.find(
                (item) => item.value == teacherRoleItem.subjectId
              );
              const levelObj = this.levelOptions.find(
                (it) => it.value == noneEmptyArray.level
              );
              this.subjectClick(levelObj, subjectObj);
            } else {
              this.initLevelSubject(subjectLevelObj, levels);
            }
          } else {
            this.initLevelSubject(subjectLevelObj, levels);
          }
        }
      }
    },
    initLevelSubject(subjectLevelObj) {
      const levelObj = this.levelOptions[this.levelOptions.length - 1];
      const noneEmptyArray = subjectLevelObj.find(
        (item) => item.options.length > 0 && levelObj.value == item.level
      );
      if (levelObj && noneEmptyArray.options.length) {
        this.subjectClick(levelObj, noneEmptyArray.options[1]);
      }
    },
    submit() {
      if (this.notWord) {
        this.$message({
          type: "error",
          message: "请选择正确的文件格式",
          showClose: true,
        });
        this.loading = false;
        return false;
      }
      if (this.isOver) {
        this.$message({
          type: "error",
          message: "文件大小不能超过5M",
          showClose: true,
        });
        this.loading = false;
        return false;
      }
      this.uploadAvatarURL = `/homework/homework/upload/homework?level=${this.levelId}&subjectId=${this.subjectId}`;
      this.$nextTick(() => {
        this.$refs.elUpload.submit();
      });
    },
    uploadChange(file) {
      if (!this.fileObj || this.fileObj.uid != file.uid) {
        this.isOver = file.size / 1024 / 1024 > 5;
        var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
        this.notWord = testmsg !== "doc" && testmsg !== "docx";
        if (this.isOver || this.notWord) {
          this.fileObj = null;
          this.$refs.upload.clearFiles();
        } else {
          this.fileObj = { ...file };
          this.fileList = [file];
          this.notWord = false;
          this.isOver = false;
        }
      }
    },
    uploadRemove() {
      this.fileList = [];
    },
    uploadSuccess(res) {
      if (res.data == 0) {
        this.$message({
          type: "warning",
          message: "解析失败",
          showClose: true,
        });

        this.fileList = [];
        this.loading = false;
        return false;
      }
      if (res.code != 0) {
        this.$message({
          type: "warning",
          message: res.msg,
          showClose: true,
        });

        this.fileList = [];
        this.loading = false;
      } else {
        this.$router.push({
          path: "/homework/paperDetails",
          query: { id: res.data },
        });
        this.loading = false;
        this.$message({
          type: "success",
          message: "上传成功",
          showClose: true,
        });
        localStorage.setItem(
          "homeworkUpload",
          JSON.stringify({
            level: { label: this.levelName, value: this.levelId },
            subject: { label: this.subjectName, value: this.subjectId },
          })
        );
        this.fileList = [];
      }
    },
    uploadError() {
      this.loading = false;
      this.disabledSubmit = true;
    },
    handleClose() {
      this.loading = false;
      this.dialogVisible = false;
    },
    init() {
      this.dialogVisible = true;
      const storage = localStorage.getItem("homeworkUpload");
      if (storage) {
        const data = JSON.parse(storage);
        this.levelName = data.level.label;
        this.subjectName = data.subject.label;
        this.levelId = data.level.value;
        this.subjectId = data.subject.value;
      }
    },
    subjectClick(val, subVal) {
      this.levelName = val.label;
      this.subjectName = subVal.label;
      this.levelId = val.value;
      this.subjectId = subVal.value;
      localStorage.setItem(
        "homeworkUpload",
        JSON.stringify({
          level: { label: this.levelName, value: this.levelId },
          subject: { label: this.subjectName, value: this.subjectId },
        })
      );
    },
    async downloadTemplate() {
      const res = await templateModel({ subjectId: this.subjectId });
      const { href } = this.$router.resolve({
        path: "/downFile",
        query: {
          url: res.data.data,
          name: this.subjectName + "-试卷上传模板",
          type: 1,
        },
      });
      let timer = setTimeout(() => {
        window.open(href, "_blank");
        clearTimeout(timer);
      }, 0);
    },
  },
};
</script>
<style lang="scss" scoped>
.subject-box {
  cursor: pointer;
  position: relative;
  z-index: 1;
  padding: 12.5px 15px 12.5px 0;
  width: fit-content;

  &:hover {
    .subject-box-hover {
      display: block;
    }
  }
  .subject-box-hover {
    left: 0;
    top: 46px;
    position: absolute;
    border: 1px solid $primary-color;
    padding: 8px 14px 28px 14px;
    background-color: #f5f5f5;
    width: 412px;
    display: none;
    cursor: auto;
    .el-icon-caret-top {
      position: absolute;
      left: 36px;
      top: -18px;
      font-size: 25px;
    }
    p {
      color: rgba(0, 0, 0, 0.85);
      font-weight: bold;
      margin-top: 20px;
    }
    .hover-list-label.hover-list-label-1 {
      justify-content: flex-start;
      span {
        margin-right: 6px;
      }
    }
    .hover-list-label {
      display: flex;
      justify-content: space-between;
      color: rgba(0, 0, 0, 0.85);
      .subject-item {
        cursor: pointer;
      }
      span {
        white-space: nowrap;
        &:hover {
          color: $primary-color;
        }
      }
      .on {
        color: $primary-color;
      }
    }
  }
  i {
    color: $primary-color;
  }
  color: $primary-color;
}
::v-deep .el-upload {
  width: 100%;
}
::v-deep .el-upload-dragger {
  width: 100%;
}
.tips {
  margin: 18px 0;
}
.error-tip {
  box-sizing: border-box;
  padding: 0 18px;
  border-radius: 4px;
  height: 40px;
  line-height: 40px;
  text-align: left;
  color: #292626;
  font-size: 14px;
  letter-spacing: 1px;
  margin-bottom: 18px;
  background: #fff3f3;
  img {
    margin-right: 3px;
  }
}
</style>
